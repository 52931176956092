<template>
  <div>
    <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on }">
        <span
          :style="item.attendence == 'P' ? 'cursor: pointer' : ''"
          :class="get_color(item)"
          v-on="on"
          @click="item.attendence == 'P' ? GeoDialog(item) : ''"
          v-if="item"
          v-text="item.attendence == 'P' ? item.no_of_swipes : item.attendence"
        ></span>
      </template>
      <span
        v-if="item"
        class="white--text"
        v-text="
          item.attendence == 'P'
            ? 'View Geo Swipes'
            : item.attendence == 'W/O'
            ? 'Week Off'
            : item.attendence == 'A'
            ? 'Absent'
            : item.attendence == 'H'
            ? 'Holiday'
            :item.attendence=='FL'
            ?'Full Leave'
            :item.attendence=='HL'
            ?'Half Leave'
            : item.attendence
        "
      ></span>
    </v-tooltip>

    <!-- <v-tooltip bottom color="primary">
      <template v-slot:activator="{ on }">
        <a v-on="on" @click="GeoDialog()"
          ><span>
            {{
              item != undefined
                ? item.attendence != undefined
                  ? item.attendence == "P"
                    ? item.no_of_swipes != undefined
                      ? item.no_of_swipes
                      : ""
                    : item.attendence
                  : ""
                : ""
            }}
          </span>
        </a>
      </template>
      <span
        v-if="
          item != undefined
            ? item.attendence != undefined
              ? item.attendence == 'P'
                ? true
                : false
              : false
            : false
        "
        class="white--text"
        >View Geo</span
      >
      <span
        v-if="
          item != undefined
            ? item.attendence != undefined
              ? item.attendence == 'A'
                ? true
                : false
              : false
            : false
        "
        class="white--text"
        >Absent</span
      >
      <span
        v-if="
          item != undefined
            ? item.attendence != undefined
              ? item.attendence == 'W/O'
                ? true
                : false
              : false
            : false
        "
        class="white--text"
        >Week Off</span
      >
      <span
        v-if="
          item != undefined
            ? item.attendence != undefined
              ? item.attendence == 'N/A'
                ? true
                : false
              : false
            : false
        "
        class="white--text"
        >Not Available</span
      >
      <span
        v-if="
          item != undefined
            ? item.attendence != undefined
              ? item.attendence == 'H'
                ? true
                : false
              : false
            : false
        "
        class="white--text"
        >Holiday</span
      >
      <span
        v-if="
          item != undefined
            ? item.attendence != undefined
              ? item.attendence == 'D/A'
                ? true
                : false
              : false
            : false
        "
        class="white--text"
        >Deactivated</span
      >
      <span
        v-if="
          item != undefined
            ? item.attendence != undefined
              ? item.attendence == 'L'
                ? true
                : false
              : false
            : false
        "
        class="white--text"
        >Leave</span
      >
    </v-tooltip> -->
    <ViewGeoSwipeCount
      :geoDialog="geoDialog"
      :item="item"
      :userName="userName"
      @close_dialog="geoDialog = false"
    />
  </div>
</template>

<script>
import ViewGeoSwipeCount from "@/components/Dialogs/ViewGeoSwipeCount.vue";

export default {
  props: {
    item: Object,
    userName: String,
  },
  components: {
    ViewGeoSwipeCount,
  },
  data: () => ({
    geoDialog: false,
  }),
  methods: {
    GeoDialog(item) {
      this.item = item;
      this.geoDialog = true;
    },
    get_color(item) {
      if (item.attendence == "P") {
        return "primary--text";
      }
      if (item.attendence == "A") {
        return "red--text";
      }
      if (item.attendence == "H") {
        return "purple--text";
      }
      if (item.attendence == "N/A") {
        return "grey--text";
      }
      if(item.attendence=="FL"){
        return "primary--text"
      }
      if(item.attendence=="HL"){
        return "primary--text"
      }
    },
  },
};
</script>

<style></style>
