<template>
  <div>
    <v-dialog v-model="geoDialog" persistent max-width="1200px">
      <v-toolbar dense class="elevation-0 gradient-bg white--text">
        <v-icon dark left>mdi-map-marker-check</v-icon>
        <v-toolbar-title
          >{{ userName }} - Swipe Locations -
          {{ currentDate }}
          <!-- {{
            item.dynamo_date.toString().slice(0, 4) +
            "-" +
            item.dynamo_date.toString().slice(4, 6) +
            "-" +
            item.dynamo_date.toString().slice(6, 8)
          }} -->
        </v-toolbar-title>
        <v-spacer />
        <v-icon @click="close_dialog()" class="white--text mr-n3"
          >mdi-close</v-icon
        >
      </v-toolbar>
      <v-card class="overflow-hidden">
        <v-row no-gutters>
          <v-col class="mt-3">
            <v-data-table
              class="mx-3"
              :headers="membersSwipeHeaders"
              :items-per-page="10"
              :items="swipeData"
              :loading="isLoadingMember"
              loading-text="Loading... Please wait"
              dense
            >
              <template v-slot:[`item.source`]="{ item }">
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <div v-on="on" v-if="item.source == 'WHATSAPP'">
                      <v-icon color="#757575" small>mdi-whatsapp</v-icon>
                    </div>
                    <div v-on="on" v-if="item.source == 'LIVE'">
                        <v-icon color="#757575" small>mdi-map-marker-account</v-icon>
                      </div>
                    <div v-on="on" v-if="item.source == 'FACE'">
                      <v-icon color="#757575" small
                        >mdi-face-recognition</v-icon
                      >
                    </div>
                    <div v-on="on" v-else-if="item.source == 'CAMERA'">
                      <v-icon color="#757575" small>mdi-camera</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'RESWIPE'">
                      <v-icon color="#757575" small>mdi-redo-variant</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'DATACORRECTION'">
                      <v-icon color="#757575" small>mdi-human-edit</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'PROXY'">
                      <v-icon color="#757575" small>mdi-odnoklassniki</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'BOX'">
                      <v-icon color="#757575" small
                        >mdi-card-account-details-outline</v-icon
                      >
                    </div>
                    <div v-on="on" v-else-if="item.source == 'SIMPLE'">
                      <v-icon color="#757575" small>mdi-line-scan</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'BEACON'">
                      <v-icon color="#757575" small>mdi-bluetooth-audio</v-icon>
                    </div>
                    <div v-on="on" v-else-if="item.source == 'DESKTOP'">
                      <v-icon color="#757575" small> mdi-desktop-classic</v-icon>
                    </div>
                  </template>
                  <span v-if="item.source == 'WHATSAPP'" class="white--text"
                    >Source: Whatsapp</span
                  >
                  <span v-else-if="item.source == 'DESKTOP'" class="white--text"
                    >Source : Desktop</span
                  >
                  <span v-else-if="item.source == 'CAMERA'" class="white--text"
                    >Source: QR</span
                  >
                  <span v-else-if="item.source == 'FACE'" class="white--text"
                    >Source: FACE</span
                  >
                  <span class="white--text" v-else-if="item.source == 'RESWIPE'"
                    >Source: Re-Scan</span
                  >
                  <span
                    v-else-if="item.source == 'DATACORRECTION'"
                    class="white--text"
                  >
                    Source: Data Correction</span
                  >
                  <span v-else-if="item.source == 'PROXY'" class="white--text">
                    Source: PROXY</span
                  >
                  <span v-else-if="item.source == 'BOX'" class="white--text">
                    Source: BOX</span
                  >
                  <span v-else-if="item.source == 'BEACON'" class="white--text">
                    Source: BEACON</span
                  >
                  <span v-else-if="item.source == 'SIMPLE'" class="white--text">
                    Source : Simple</span
                  >
                  <span
                      v-else-if="item.source == 'LIVE'"
                      class="white--text"
                    >
                    Source : LIVE</span>
                </v-tooltip>
              </template>
              <template v-slot:[`item.swipe_time_stamp`]="{ item }">
                <span
                  v-text="get_date(item.swipe_time_stamp).split(',')[1]"
                ></span>
              </template>
              <template v-slot:no-data>
                <v-alert :value="true" class="gradient-bg white--text">
                  No swipes recorded for this member!
                </v-alert>
              </template>
            </v-data-table>
          </v-col>
          <v-col>
            <v-card class="mx-4 mt-4">
              <GmapMap
                v-if="geoDialog"
                :center="
                  membersSwipeGeoData[0] != undefined
                    ? membersSwipeGeoData[0].position
                    : defaultCenter
                "
                :zoom="18"
                map-type-id="terrain"
                style="width: 100%; height: 415px"
              >
                <GmapMarker
                  :key="index"
                  v-for="(m, index) in membersSwipeGeoData"
                  :position="m.position"
                  :clickable="false"
                  :draggable="false"
                  :title="
                    ' swipped at location: ' +
                    m.location +
                    '\nswipped time: ' +
                    m.time
                  "
                />
              </GmapMap>
            </v-card>
          </v-col>
        </v-row>
        <v-card-actions />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { GetParticularMembersScans } from "@/graphql/queries.js";

export default {
  props: {
    item: Object,
    userName: String,
    geoDialog: Boolean,
  },
  data: () => ({
    currentDate: "",
    isLoadingMember: false,
    swipeData: [],
    membersSwipeHeaders: [
      { text: "", value: "source" },
      { text: "Swipe Location", value: "box_location" },
      { text: "Swipe Time", value: "swipe_time_stamp" },
    ],
    membersSwipeGeoData: [],
    defaultCenter: {
      lng: 0,
      lat: 0,
    },
  }),
  watch: {
    geoDialog(val) {
      if (val) {
        this.isLoadingMember = true;
        this.getGeoData(this.item);
        // console.log("ITEM", this.item);
      }
    },
  },
  methods: {
    get_date(date) {
      let a = new Date(date * 1000).toLocaleTimeString("en-In", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return a.toUpperCase();
    },
    async getGeoData(item) {
      this.swipeData = [];
      try {
        await API.graphql(
          graphqlOperation(GetParticularMembersScans, {
            input: {
              organisation_id:
                this.$store.getters.get_org_details[0].organisation_id,
              user_id: item.user_id,
              swipe_date:
                item.dynamo_date != undefined || item.dynamo_date != null
                  ? item.dynamo_date.toString().slice(0, 4) +
                    "-" +
                    item.dynamo_date.toString().slice(4, 6) +
                    "-" +
                    item.dynamo_date.toString().slice(6, 8)
                  : "-",
            },
          })
        ).then((res) => {
          this.currentDate =
            item.dynamo_date != undefined || item.dynamo_date != null
              ? item.dynamo_date.toString().slice(0, 4) +
                "-" +
                item.dynamo_date.toString().slice(4, 6) +
                "-" +
                item.dynamo_date.toString().slice(6, 8)
              : "-";
          this.swipeData = JSON.parse(res.data.GetParticularMembersScans).Items;
          for (let i = 0; i < this.swipeData.length; i++) {
            this.membersSwipeGeoData.push({
              location: this.swipeData[i].box_location,
              time: this.get_date(this.swipeData[i].swipe_time_stamp).split(
                ","
              )[1],
              position: {
                lat: Number(this.swipeData[i].user_lat),
                lng: Number(this.swipeData[i].user_long),
              },
            });
          }
          // console.log(res);
        });
        this.isLoadingMember = false;
      } catch (err) {
        console.log(err);
        this.isLoadingMember = false;
      }
    },
    close_dialog() {
      this.$emit("close_dialog");
    },
  },
};
</script>

<style></style>
